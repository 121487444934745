export const addUnit = (val) => {
  if (!val) {
    return val;
  }

  return `${val}`.includes('%') || `${val}`.includes('px') ? `${val}` : `${val}px`;
};

export const setIframeDimensions = (iframe, data) => {
  iframe.width = data.data.w;
  iframe.height = data.data.h;
  iframe.style.width = addUnit(data.data.w);
  iframe.style.maxWidth = '100%';
};

export const resetIframeDimensionsForResize = (iframe) => {
  iframe.width = '100%';
  iframe.style.width = '';
  iframe.style.maxWidth = '100%';
};

export const objToQueryParams = (initialObj) => {
  const reducer = (obj, parentPrefix = null) => (prev, key) => {
    const val = obj[key];
    key = encodeURIComponent(key);
    const prefix = parentPrefix ? `${parentPrefix}[${key}]` : key;

    if (val == null || typeof val === 'function') {
      prev.push(`${prefix}=`);
      return prev;
    }

    if (['number', 'boolean', 'string'].includes(typeof val)) {
      prev.push(`${prefix}=${encodeURIComponent(val)}`);
      return prev;
    }

    prev.push(Object.keys(val).reduce(reducer(val, prefix), []).join('&'));
    return prev;
  };

  return Object.keys(initialObj).reduce(reducer(initialObj), []).join('&');
};

export default null;
